import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon, Font } from '@politechdev/blocks-design-system'
import { RecordContext } from '../RecordContext/RecordContext'
import styles from './RecordPager.module.scss'

const RecordPager = () => {
  const { t } = useTranslation()
  const { handlePageChange, row, setRow, recordCount } =
    useContext(RecordContext)

  useEffect(() => {
    if (recordCount && row > recordCount) {
      setRow(row - 1)
    }
  }, [row, recordCount])

  return (
    <div className={styles.pager}>
      <Button.Secondary
        disabled={row === 0 || row === 1}
        onClick={() => {
          handlePageChange(-1)
        }}
      >
        <Icon.ChevronLeft />
      </Button.Secondary>
      <Font.Copy variant="hint">
        {recordCount ? `${row} ${t('of')} ${recordCount}` : t('Loading...')}
      </Font.Copy>
      <Button.Secondary
        disabled={row + 1 > recordCount}
        onClick={() => {
          handlePageChange(+1)
        }}
      >
        <Icon.ChevronRight />
      </Button.Secondary>
    </div>
  )
}

export default RecordPager
