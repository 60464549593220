import { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { View, ViewContainer, CardError } from 'components'
import {
  Button,
  ButtonBlock,
  Icon,
  PageHeader,
  Sheet,
} from '@politechdev/blocks-design-system'
import { useRoutePathParams, useReactRouter, useRequest } from 'hooks'
import { fetchEvent } from 'requests/events'
import { useCurrent } from 'contexts/index'
import { formatErrorMessage } from 'utils/formatting'
import EventDeleteModal from '../EventDeleteModal/EventDeleteModal'
import EventDetails from '../EventDetails/EventDetails'
import EventInviteLink from '../EventInviteLink/EventInviteLink'
import GuestSummary from '../GuestSummary/GuestSummary'
import EventAttachments from '../EventAttachments/EventAttachments'
import EventTabs from '../EventTabs/EventTabs'
import styles from './EventSingle.module.scss'
import EventContextProvider, {
  EventContext,
} from '../EventContext/EventContext'

const EventSingle = () => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const [{ id: eventId }] = useRoutePathParams()

  const { doesCurrentUserHavePermission } = useCurrent()

  const canModifyEvent = doesCurrentUserHavePermission({
    resource: 'event',
    ability: 'modify',
  })

  const { currentEvent, setCurrentEvent } = useContext(EventContext)

  const { makeRequest, isLoading, errors, isRequestComplete } = useRequest(
    fetchEvent,
    {
      onSuccess: ({ event }) => {
        setCurrentEvent({
          ...event,
          shifts: (event.shifts || []).map(s => ({
            ...s,
            volunteers: s.volunteerships,
          })),
        })
      },
    }
  )

  const errorMsg = formatErrorMessage(errors)

  useEffect(() => {
    makeRequest(eventId, {
      fields: [
        'id',
        'slug',
        'name',
        'start_time',
        'end_time',
        'notes',
        'description',
        'public',
        'invited_count',
        'attended_count',
        'walk_in_count',
        'public_page_header_file_locator',
        'type',
        'url',
        'url_label',
        'public_settings',
        { location: ['name', 'street_address', 'city', 'state', 'zipcode'] },
        { organization: ['name', 'id'] },
        { coorganizations: ['name'] },
        { teams: ['name'] },
        { campaigns: ['name', 'id'] },
        { documents: ['file_data', 'name', 'file_url'] },
        {
          shifts: [
            'id',
            'start_time',
            'end_time',
            {
              volunteerships: [
                'id',
                'responsibility',
                { person: ['id', 'name'] },
              ],
            },
          ],
        },
        {
          turf: ['activism_config'],
        },
      ],
    })
  }, [eventId])

  const eventLoaded =
    isRequestComplete &&
    !errorMsg &&
    (currentEvent?.id === +eventId || currentEvent?.slug === eventId)

  return (
    <View>
      <ViewContainer loading={isLoading}>
        <CardError
          hide={!errorMsg}
          message={t("We're unable to retrieve this event")}
        />
        {eventLoaded && (
          <>
            <Sheet>
              <PageHeader
                title={currentEvent.name}
                subtitle={
                  currentEvent.public_settings?.title
                    ? t('Public title: {{ title }}', {
                        title: currentEvent.public_settings.title,
                      })
                    : undefined
                }
              >
                <ButtonBlock justify="right">
                  <Link
                    onClick={e => !canModifyEvent && e.preventDefault()}
                    to={`${match.url}/edit`}
                  >
                    <Button disabled={!canModifyEvent}>
                      <Icon.Pencil />
                    </Button>
                  </Link>
                  <EventDeleteModal event={currentEvent} />
                </ButtonBlock>
              </PageHeader>
              <div className={styles.container}>
                <EventDetails />
                <div>
                  <EventInviteLink />
                  <GuestSummary />
                  <EventAttachments />
                </div>
              </div>
            </Sheet>
            <Sheet>
              <EventTabs />
            </Sheet>
          </>
        )}
      </ViewContainer>
    </View>
  )
}

export default () => (
  <EventContextProvider>
    <EventSingle />
  </EventContextProvider>
)
