import { useTranslation } from 'react-i18next'
import { useToast } from '@politechdev/blocks-design-system'
import React, { createContext, useEffect, useState } from 'react'
import { useRequest, UseRequestData } from 'hooks/useRequest'
import { scanFields } from 'requests/scans'
import { useContextOrThrow } from 'utils/contexts'
import { useRouteQueryParams } from 'hooks/router'
import { useEvent } from 'hooks'
import { putScan } from 'requests/shifts'
import { fetchScan, redoVisualQc } from './requests'
import { useCurrentPacket } from './PacketContext'
import { Scan } from './types'

type ScanContextState = {
  currentScan: Scan | null
  setCurrentScanId: (scanId: number) => void
  scanRequest: Omit<
    UseRequestData<Scan>,
    'makeRequest' | 'response' | 'clearRequest'
  > & { reload: () => Promise<void> }
  actions: ScanContextActions
}

type ScanContextActions = {
  clearCurrent: () => void
  updateScan: (scan: { id: Scan['id'] }, params: object) => Promise<Scan>
  approveCurrentScan: () => Promise<Scan> | undefined
  disapproveScan: (
    scanId: number,
    notes: string,
    issueReasons: number[]
  ) => Promise<Scan>
  redoScanResponses: (scanId: number) => Promise<void>
}

const ScanContext = createContext<ScanContextState | undefined>(undefined)

export const QueryParamScanProvider: React.FC = ({ children }) => {
  const [_packet, setCurrentPacketId, { reload: reloadPacket }] =
    useCurrentPacket()
  const [queryParams, setQueryParams] = useRouteQueryParams()
  const { scanId: scanIdStr } = queryParams
  const setCurrentScanId = (scanId?: number) =>
    setQueryParams({ ...queryParams, scanId })
  const scanId = scanIdStr ? +scanIdStr : undefined

  const [currentScan, setCurrentScan] = useState<Scan | null>(null)

  const { setToast } = useToast()
  const { t } = useTranslation()
  const { makeRequest, response, ...scanRequest } = useRequest(fetchScan, {
    onSuccess: setCurrentScan,
    onError: () =>
      setToast({
        message: t('There was an unknown error loading a part of this page.'),
        variant: 'error',
        onClose: () => null,
      }),
  })

  const reload = async () => {
    await makeRequest(scanId)
  }

  useEffect(() => {
    scanRequest.clearRequest()
    if (scanId) {
      void makeRequest(scanId)
    }
  }, [scanId])

  const clearCurrent = () => {
    setCurrentPacketId(undefined)
    setCurrentScanId(undefined)
  }

  const updateScan = useEvent(
    async (scan: { id: Scan['id'] }, params: object = scanFields) => {
      const { registration_form: updatedScan } = (await putScan(
        scan.id,
        scan,
        params
      )) as { registration_form: Scan }

      void reloadPacket()
      return updatedScan
    }
  )

  const approveCurrentScan = useEvent(() => {
    if (!currentScan) return

    const params = { id: currentScan.id, visual_review_approved: true }

    return updateScan(params, scanFields)
  })

  const disapproveScan = useEvent(
    (disapprovedScanId: number, notes: string, issueReasons: number[]) => {
      const disapprovedScan = {
        id: disapprovedScanId,
        notes,
        visual_reviews: issueReasons.map(issue => ({
          visual_review_response_id: issue,
        })),
      }

      return updateScan(disapprovedScan)
    }
  )

  const redoScanResponses = useEvent(async () => {
    if (!currentScan) return

    await redoVisualQc(currentScan)

    setCurrentScan({
      ...currentScan,
      notes: '',
      visual_review_approved: false,
      visual_reviews: [],
    })
  })

  return (
    <ScanContext.Provider
      value={{
        currentScan,
        setCurrentScanId,
        scanRequest: { ...scanRequest, reload },
        actions: {
          updateScan,
          approveCurrentScan,
          disapproveScan,
          clearCurrent,
          redoScanResponses,
        },
      }}
    >
      {children}
    </ScanContext.Provider>
  )
}

export function useCurrentScan() {
  const { currentScan, setCurrentScanId, scanRequest } =
    useContextOrThrow(ScanContext)
  return [currentScan, setCurrentScanId, scanRequest] as const
}

export function useScanActions() {
  const { actions } = useContextOrThrow(ScanContext)
  return actions
}
