import { CardError } from 'components'
import {
  Button,
  ContentBlock,
  ButtonBlock,
  ProgressBar,
  PageHeader,
  useToast,
} from '@politechdev/blocks-design-system'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useReactRouter, useRequest } from 'hooks'
import {
  deleteImportError,
  postImportError,
} from 'requests/duplicatePrevention'
import RecordContextProvider, {
  RecordContext,
} from '../RecordContext/RecordContext'
import RecordError from '../RecordError/RecordError'
import RecordPager from '../RecordPager/RecordPager'
import styles from './Record.module.scss'
import RecordDuplicates from '../RecordDuplicates/RecordDuplicates'

const Record = () => {
  const { setToast } = useToast()
  const { t } = useTranslation()
  const {
    fetchErrorRowsRequest,
    importRecord,
    duplicateRes,
    duplicateReqLoading,
    fetchImportDataRequest,
    row,
    setRow,
    recordCount,
  } = useContext(RecordContext)

  const { match } = useReactRouter()

  const onSuccess = () => {
    setToast({
      message: t('Success'),
      variant: 'success',
    })
    const timeout = setTimeout(() => {
      clearTimeout(timeout)
      fetchErrorRowsRequest.makeRequest()
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      if (row === 0) {
        setRow(1)
      }
    }, 1500)
  }

  const deleteImportErrorRequest = useRequest(deleteImportError, { onSuccess })

  const saveImportErrorRequest = useRequest(postImportError, { onSuccess })

  const hasPendingImportRecordChanges =
    deleteImportErrorRequest.isLoading || saveImportErrorRequest.isLoading

  const hasErrorMakingChanges =
    deleteImportErrorRequest.hasErrors || saveImportErrorRequest.hasErrors

  return (
    <>
      <PageHeader
        title={
          recordCount !== undefined
            ? `${recordCount} ${t(
                `import validation error${recordCount === 1 ? '' : 's'} to resolve`
              )}`
            : '...'
        }
        className={styles.header}
      >
        <ButtonBlock justify="right">
          <RecordPager />
          <Link
            to={`/data_sources/imports/${match.params.importId}/duplicate_prevention/`}
          >
            <Button.Secondary>{t('Review in bulk')}</Button.Secondary>
          </Link>
        </ButtonBlock>
      </PageHeader>
      <ProgressBar
        show={
          fetchErrorRowsRequest.isLoading || fetchImportDataRequest.isLoading
        }
      />
      {(fetchErrorRowsRequest.hasError || fetchImportDataRequest.hasError) && (
        <ContentBlock>
          <CardError />
        </ContentBlock>
      )}
      {importRecord && (
        <div className={styles.container}>
          <div className={styles.container__main}>
            <RecordError
              isLoadingChanges={hasPendingImportRecordChanges}
              hasErrorMakingChanges={hasErrorMakingChanges}
              deleteImportErrorRequest={deleteImportErrorRequest}
              saveImportErrorRequest={saveImportErrorRequest}
            />
          </div>
          {duplicateRes &&
            !duplicateReqLoading &&
            !hasPendingImportRecordChanges && (
              <div className={styles.container__sidepanel}>
                <RecordDuplicates />
              </div>
            )}
        </div>
      )}
    </>
  )
}

export default () => (
  <RecordContextProvider>
    <Record />
  </RecordContextProvider>
)
