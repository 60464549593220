import { useTranslation } from 'react-i18next'
import { Section, Grid } from '@politechdev/blocks-design-system'
import { useCurrent } from 'contexts'
import ActivityLog from './ActivityLog/ActivityLog'
import PhoneBankLog from './PhoneBankLog/PhoneBankLog'

const PersonContactLog = ({ personId }) => {
  const { t } = useTranslation()
  const { doesCurrentUserHavePermission } = useCurrent()

  const hasPhoneBankPermissions = doesCurrentUserHavePermission({
    resource: 'phone_banking/call',
    ability: 'view',
  })

  return (
    <Section label={t('Contact log')} collapsed>
      <Section>
        <ActivityLog personId={personId} />
      </Section>
      {hasPhoneBankPermissions && (
        <Section>
          <PhoneBankLog personId={personId} />
        </Section>
      )}
      <Grid />
    </Section>
  )
}

export default PersonContactLog
