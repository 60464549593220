import { StaticListItem } from 'components'
import {
  Button,
  ButtonBlock,
  Icon,
  Font,
  TextBlock,
} from '@politechdev/blocks-design-system'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import classNames from 'classnames/bind'
import styles from './FormViewer.module.scss'

const getIneligibleLabel = (form, requiresLookupCodes) => {
  if (form.delivery_id) {
    return `Attached to delivery #${form.delivery_id}`
  }

  if (requiresLookupCodes && !form.lookup_code) return 'Missing lookup code'

  return "Doesn't match criteria"
}

const FormListItem = ({
  isExcluded,
  deliveryId,
  form,
  isIneligible,
  requiresLookupCodes = false,
  handleExclude,
  handleInclude,
}) => {
  const { t } = useTranslation()
  const [notesTabIsOpen, setNotesTabIsOpen] = useState(false)
  const cx = classNames.bind(styles)

  const {
    county,
    notes,
    phone_verification_responses,
    scan_number,
    shift,
    id,
    flags,
    display_name = null,
  } = form
  const allNotes = notes ? [{ source: 'Visual QC', note: notes }] : []
  if (phone_verification_responses.length) {
    phone_verification_responses.forEach(
      res =>
        res.notes &&
        allNotes.push({ source: 'Phone Verification', note: res.notes })
    )
  }
  const noteCount = allNotes.length
  const notesButtonLabel = notesTabIsOpen ? 'Hide notes' : noteCount
  const ineligibleLabel = getIneligibleLabel(form, requiresLookupCodes)
  const hasIssues = !!flags.filter(flag => flag.status !== 'completed').length

  return (
    <StaticListItem
      render={() => (
        <div className={styles.item}>
          <div className={styles.item__main}>
            <TextBlock>
              <Font.Copy variant="highlight">
                {`Scan #${scan_number} - ${display_name || 'Data pending'}`}
              </Font.Copy>
              <Font.Copy variant="secondary">
                {`${county} County` || t('No county')}
              </Font.Copy>
            </TextBlock>
            <TextBlock>
              {isExcluded || isIneligible ? (
                <Font.Copy>
                  {isExcluded ? t('Excluded') : ineligibleLabel}
                  <Icon.Ban aria-label={t('Ineligible')} />
                </Font.Copy>
              ) : (
                <Font.Copy>
                  {t('Included')}
                  <Icon.Check aria-label={t('Included')} />
                </Font.Copy>
              )}
            </TextBlock>
          </div>
          <div className={styles.item__actions}>
            <ButtonBlock>
              <Button.Secondary
                onClick={() => setNotesTabIsOpen(prevState => !prevState)}
                disabled={allNotes.length === 0}
              >
                {notesTabIsOpen ? (
                  <Icon.AngleUp alt="" />
                ) : (
                  <Icon.CommentAltLines aria-label={t('Notes')} />
                )}{' '}
                <span>{notesButtonLabel}</span>
              </Button.Secondary>
              {hasIssues && (
                <>
                  <Icon.Flag alt="" />
                  <span>{t('Flag')}</span>
                </>
              )}
            </ButtonBlock>
            <ButtonBlock justify="right">
              {!isIneligible &&
                (isExcluded ? (
                  <Button onClick={() => handleInclude(form.id)}>
                    {t('Include')}
                  </Button>
                ) : (
                  <Button.Danger onClick={() => handleExclude(form.id)}>
                    {t('Exclude')}
                  </Button.Danger>
                ))}
              <Link
                to={`/collect/voter_registration/shifts/${shift.id}/forms/${id}?source=deliveries&sourceId=${deliveryId}`}
              >
                <Button>{t('Inspect')}</Button>
              </Link>
            </ButtonBlock>
          </div>
          <div
            className={cx('item__notes', {
              'item__notes--open': notesTabIsOpen,
            })}
          >
            {allNotes.map(n => (
              <TextBlock className={styles.item__notes__item}>
                <Font.Copy variant="reading-block">{n.note}</Font.Copy>
                <Font.Copy
                  variant="hint"
                  className={styles.item__notes__item__source}
                >
                  {`From ${n.source}`}
                </Font.Copy>
              </TextBlock>
            ))}
          </div>
        </div>
      )}
    />
  )
}

export default FormListItem
