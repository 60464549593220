import { useCurrent } from 'contexts/index'
import { useState, useRef, ReactNode, useEffect } from 'react'
import classNames from 'classnames/bind'
import { useHistory } from 'react-router-dom'
import { UserMenu } from 'components'
import { Font, Icon } from '@politechdev/blocks-design-system'
import { routes } from 'layout/routesConfig'
import { useNavigation } from 'layout/NavigationProvider/NavigationProvider'
import NavigationItem from './NavigationItem'
import NavigationBlock from './NavigationBlock'
import LanguageToggle from '../LanguageToggle/LanguageToggle'
import styles from './Navigation.module.scss'

type NavSection =
  | 'organize'
  | 'quality control'
  | 'petitions'
  | 'registration'
  | 'reports'

export type Permission = {
  resource: string
  ability: string
}

export type Route = {
  [label: string]: {
    path?: string
    permissions?: Permission[]
    blocks: string[]
  }
}

export type UseNavigationPermission = {
  hasBlock: boolean
  hasPermission: boolean
  hasSomePermission: boolean
  hasValidChildren: boolean
  children: ReactNode
}

export type UseNavigationHook = {
  isCollapsed: boolean
}

type UseCurrent = {
  tenantBlocks: [string]
}

const cx = classNames.bind(styles)

const Navigation = () => {
  const { tenantBlocks } = useCurrent() as UseCurrent
  const [activeSection, setActiveSection] = useState<NavSection>('registration')
  const navRef = useRef(null)
  const { isCollapsed } = useNavigation() as UseNavigationHook
  const history = useHistory()

  const getDefaultBlock = () => {
    if (tenantBlocks.includes('voter_registration')) return 'registration'
    if (tenantBlocks.includes('organize')) return 'organize'
    if (tenantBlocks.includes('quality_control')) return 'quality control'
    if (tenantBlocks.includes('petitions')) return 'petitions'
    return 'registration'
  }

  const doesPathMatchASubroute = (subroutes: Route) =>
    Object.values(subroutes).some(
      subroute =>
        typeof subroute.path === 'string' &&
        history.location.pathname.includes(subroute.path)
    )

  const reselectBlock = () => {
    const INDEX_OF_BLOCK_KEY = 0
    const match = Object.entries(routes).find(
      ([, subroutes]: [string, Route]) => doesPathMatchASubroute(subroutes)
    )
    setActiveSection(
      match ? (match[INDEX_OF_BLOCK_KEY] as NavSection) : getDefaultBlock()
    )
  }

  useEffect(reselectBlock, [])

  return (
    <nav
      ref={navRef}
      className={cx('navigation', { 'navigation--collapsed': isCollapsed })}
      onMouseLeave={reselectBlock}
    >
      <div className={styles.navigation__container}>
        <div className={styles.navigation__main}>
          <ul className={styles.navigation__main__items}>
            <NavigationBlock
              IconComponent={Icon.BoxBallot}
              label="Registration"
              routes={routes.registration}
              active={activeSection === 'registration'}
              onSelect={() => {
                setActiveSection('registration')
              }}
            />
            <NavigationBlock
              IconComponent={Icon.UserMagnifyingGlass}
              label="Quality Control"
              routes={routes['quality control']}
              active={activeSection === 'quality control'}
              onSelect={() => {
                setActiveSection('quality control')
              }}
            />
            <NavigationBlock
              IconComponent={Icon.Users}
              label="Organize"
              routes={routes.organize}
              active={activeSection === 'organize'}
              onSelect={() => {
                setActiveSection('organize')
              }}
            />
            <NavigationBlock
              IconComponent={Icon.ClipboardList}
              label="Petitions"
              routes={routes.petitions}
              active={activeSection === 'petitions'}
              onSelect={() => setActiveSection('petitions')}
            />
            <NavigationBlock
              IconComponent={Icon.ChartPie}
              label="Reports"
              routes={routes.reports}
              active={activeSection === 'reports'}
              onSelect={() => setActiveSection('reports')}
            />
          </ul>
        </div>
        <div className={styles.navigation__secondary}>
          <Font.Copy
            variant="hint"
            className={styles['navigation__secondary__block-label']}
          >
            {activeSection}
          </Font.Copy>
          <ul className={styles.navigation__secondary__items}>
            {Object.entries(routes[activeSection])
              .filter(([itemKey]) => itemKey !== '_meta')
              .map(([itemKey, params]) => (
                <NavigationItem key={itemKey} label={itemKey} {...params} />
              ))}
          </ul>
        </div>
      </div>
      <div
        className={cx('global-items', {
          'global-items--collapsed': isCollapsed,
        })}
      >
        <LanguageToggle />
        <UserMenu />
      </div>
    </nav>
  )
}

export default Navigation
