type FormWithReviewData = {
  visual_review_approved: boolean
  visual_reviews: any[]
}

export function formApproved(form: FormWithReviewData): boolean | null {
  if (form.visual_review_approved) {
    return true
  }
  if (form.visual_reviews.length) {
    return false
  }
  return null
}
