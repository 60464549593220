import { useTranslation } from 'react-i18next'
import { merge } from 'lodash'
import { ViewMoreButton, CardError, StateSelectField } from 'components'
import {
  TextBlock,
  Font,
  TextField,
  ProgressBar,
  FieldBlock,
  Button,
  ButtonBlock,
  Section,
  Sheet,
  ContentBlock,
  DateField,
  SelectField,
  Checkbox,
} from '@politechdev/blocks-design-system'
import React, { useContext, useEffect, useState } from 'react'
import { useReactRouter } from 'hooks'
import classNames from 'classnames/bind'
import { useDebounce } from 'use-debounce'
import moment from 'moment'
import { getExtraFields } from 'duplicatePrevention/record/utils'
import { useCurrent } from 'contexts/index'
import styles from './RecordError.module.scss'
import { RecordContext } from '../RecordContext/RecordContext'
import InvalidIndicator from '../FieldIndicator/InvalidIndicator'

const cx = classNames.bind(styles)

const RecordError = ({
  isLoadingChanges,
  hasErrorMakingChanges,
  deleteImportErrorRequest,
  saveImportErrorRequest,
}) => {
  const { t } = useTranslation()
  const { match } = useReactRouter()
  const [animated, setAnimated] = useState(false)

  const {
    resolvedFields,
    setResolvedFields,
    importRecord,
    fetchErrorRowsRequest,
    duplicateReqLoading,
    reqDuplicateUpdate,
    hasDuplicateWithSameExternalId,
  } = useContext(RecordContext)

  const { currentTurfActivismLanguages } = useCurrent()

  const [debouncedImportRecordChange] = useDebounce(importRecord, 200)

  const [debouncedFirstName] = useDebounce(resolvedFields.first_name, 200)
  const [debouncedLastName] = useDebounce(resolvedFields.last_name, 200)
  const [debouncedPhoneNumber] = useDebounce(
    resolvedFields.primary_phone_number,
    200
  )
  const [debouncedEmailAddress] = useDebounce(
    resolvedFields.primary_email_address,
    200
  )
  const [debouncedExternalId] = useDebounce(resolvedFields.external_id, 200)

  useEffect(() => {
    !animated && setAnimated(true)
  }, [debouncedImportRecordChange])

  useEffect(() => {
    reqDuplicateUpdate(resolvedFields)
  }, [
    debouncedFirstName,
    debouncedLastName,
    debouncedPhoneNumber,
    debouncedEmailAddress,
    debouncedExternalId,
  ])

  const isMissingFirstName =
    !importRecord.first_name && !resolvedFields.first_name
  const isMissingLastName = !importRecord.last_name && !resolvedFields.last_name

  const extraFields = getExtraFields(importRecord)

  const saveAsNew = () => {
    saveImportErrorRequest.makeRequest(match.params.importId, importRecord.id, {
      row_data: merge(importRecord, resolvedFields, {
        birth_date:
          resolvedFields.birth_date || importRecord.birth_date
            ? moment(
                resolvedFields.birth_date || importRecord.birth_date
              ).toISOString()
            : null,
      }),
    })
  }

  const discardRecord = () => {
    deleteImportErrorRequest.makeRequest(match.params.importId, importRecord.id)
  }

  const isSaveAsNewInvalid =
    isMissingFirstName ||
    isMissingLastName ||
    isLoadingChanges ||
    duplicateReqLoading ||
    hasDuplicateWithSameExternalId

  return (
    <Section label={`${t('Attempting to import row')} ${importRecord.id}`}>
      <ContentBlock collapsed>
        <Sheet>
          <div
            className={cx('animation-block', {
              'animation-block--animated': animated,
            })}
            onAnimationEnd={() => setAnimated(false)}
          />
          <ProgressBar show={isLoadingChanges} />
          <CardError
            hide={!hasErrorMakingChanges}
            message={`${t('Something went wrong resolving this record')}${saveImportErrorRequest.errors?.external_id ? `. External ID ${saveImportErrorRequest.errors?.external_id}` : ''}`}
          />
          <ButtonBlock>
            <Button.Accent disabled={isSaveAsNewInvalid} onClick={saveAsNew}>
              {t('Save as new')}
            </Button.Accent>
            <Button.Secondary
              disabled={isLoadingChanges || duplicateReqLoading}
              onClick={discardRecord}
            >
              {t('Discard')}
            </Button.Secondary>
          </ButtonBlock>
          <Section label={t('Name')}>
            <FieldBlock>
              <TextField
                label={t('First name')}
                value={
                  resolvedFields.first_name !== undefined
                    ? resolvedFields.first_name
                    : importRecord.first_name ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    first_name: val,
                  }))
                }
                required
              />
              <TextField
                label={t('Middle name')}
                value={
                  resolvedFields.middle_name !== undefined
                    ? resolvedFields.middle_name
                    : importRecord.middle_name ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    middle_name: val,
                  }))
                }
              />
              <TextField
                label={t('Last name')}
                value={
                  resolvedFields.last_name !== undefined
                    ? resolvedFields.last_name
                    : importRecord.last_name ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    last_name: val,
                  }))
                }
                required
              />
            </FieldBlock>
          </Section>
          <Section label={t('Primary phone')}>
            <InvalidIndicator section="primary_phone_number" />
            <FieldBlock>
              <TextField
                type="tel"
                value={
                  resolvedFields.primary_phone_number !== undefined
                    ? resolvedFields.primary_phone_number
                    : importRecord.primary_phone_number?.replace(/\D/g, '') ??
                      ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    primary_phone_number: val,
                  }))
                }
              />
            </FieldBlock>
          </Section>
          <Section label={t('Receives sms')}>
            <InvalidIndicator section="receives_sms" />
            <FieldBlock>
              <Checkbox
                label={t('Receives sms')}
                checked={
                  resolvedFields.receives_sms !== undefined
                    ? resolvedFields.receives_sms
                    : importRecord.receives_sms
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    receives_sms: val,
                  }))
                }
              />
            </FieldBlock>
          </Section>
          <Section label={t('Primary email')}>
            <InvalidIndicator section="primary_email_address" />
            <FieldBlock>
              <TextField
                value={
                  resolvedFields.primary_email_address !== undefined
                    ? resolvedFields.primary_email_address
                    : importRecord.primary_email_address ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    primary_email_address: val,
                  }))
                }
              />
            </FieldBlock>
          </Section>
          <Section label={t('Primary language')}>
            <InvalidIndicator section="primary_language" />
            <FieldBlock>
              <SelectField
                value={
                  resolvedFields.primary_language !== undefined
                    ? resolvedFields.primary_language
                    : importRecord.primary_language ?? ''
                }
                onSelect={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    primary_language: val,
                  }))
                }
                options={currentTurfActivismLanguages.map(language => ({
                  label: language,
                  value: language,
                }))}
              />
            </FieldBlock>
          </Section>
          <Section label={t('Address')}>
            <InvalidIndicator section="residential_address" />
            <FieldBlock>
              <TextField
                label={t('Address line 1')}
                value={
                  resolvedFields.residential_address?.line_one !== undefined
                    ? resolvedFields.residential_address.line_one
                    : importRecord.residential_address?.line_one ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      line_one: val,
                    },
                  }))
                }
              />
              <TextField
                label={t('Address line 2')}
                value={
                  resolvedFields.residential_address?.line_two !== undefined
                    ? resolvedFields.residential_address.line_two
                    : importRecord.residential_address?.line_two ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      line_two: val,
                    },
                  }))
                }
              />
              <TextField
                label={t('City')}
                value={
                  resolvedFields.residential_address?.city !== undefined
                    ? resolvedFields.residential_address.city
                    : importRecord.residential_address?.city ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      city: val,
                    },
                  }))
                }
              />
              <TextField
                label={t('County')}
                value={
                  resolvedFields.residential_address?.county !== undefined
                    ? resolvedFields.residential_address.county
                    : importRecord.residential_address?.county ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      county: val,
                    },
                  }))
                }
              />
              <StateSelectField
                label={t('State')}
                state={
                  resolvedFields.residential_address?.state !== undefined
                    ? resolvedFields.residential_address.state
                    : importRecord.residential_address?.state ?? ''
                }
                onSelect={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      state: val,
                    },
                  }))
                }
              />
              <TextField
                label={t('Zipcode')}
                value={
                  resolvedFields.residential_address?.zipcode !== undefined
                    ? resolvedFields.residential_address.zipcode
                    : importRecord.residential_address?.zipcode ?? ''
                }
                error={
                  resolvedFields.residential_address?.zipcode !== undefined &&
                  !resolvedFields.residential_address.zipcode.match(
                    /^\d{5}(-\d{4})?$/
                  )
                }
                errorMsg={t('Invalid zip code')}
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      zipcode: val,
                    },
                  }))
                }
              />
            </FieldBlock>
          </Section>
          <Section label={t('Date of birth')}>
            <InvalidIndicator section="birth_date" />
            <FieldBlock>
              <DateField
                value={
                  resolvedFields.birth_date !== undefined
                    ? resolvedFields.birth_date
                    : importRecord.birth_date ?? ''
                }
                onChange={val => {
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    birth_date: val,
                  }))
                }}
              />
            </FieldBlock>
          </Section>
          <Section label={t('External ID')}>
            <InvalidIndicator section="external_id" />
            <FieldBlock>
              <TextField
                value={
                  resolvedFields.external_id !== undefined
                    ? resolvedFields.external_id
                    : importRecord.external_id ?? ''
                }
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    external_id: val,
                  }))
                }
                error={hasDuplicateWithSameExternalId}
                errorMessage={t('External id already taken')}
              />
            </FieldBlock>
          </Section>
          {!!extraFields.length && (
            <ViewMoreButton name="field" count={extraFields.length}>
              {extraFields.map(
                field =>
                  field.value && (
                    <TextBlock>
                      <Font.Label>{t(field.label)}</Font.Label>
                      <Font.Copy>{field.value}</Font.Copy>
                    </TextBlock>
                  )
              )}
            </ViewMoreButton>
          )}
          <ProgressBar
            show={duplicateReqLoading && !fetchErrorRowsRequest.isLoading}
          />
        </Sheet>
      </ContentBlock>
    </Section>
  )
}

export default RecordError
