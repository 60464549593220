import { buildDefaultFilters } from 'utils/reporting'
import { partisanColumnAppends } from 'registrationReports/reportsConfig/columns/partisanColumnAppends'
import locationConfig from './location'

export default {
  id: 'location_party',
  name: 'Field (partisan)',
  permissionId: 'cover_sheet_party_report',
  description:
    'This report provides information on location performance. The "Address and Coordinates" tab provides information useful for mapping and tracking locations. The "Canvass Totals" tab provides information from the cover sheet and information related to the shift at that location. The "Shift Performance" tab compares shifts with field start times before noon versus field start times that being noon or later.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1xABMR2DqVMaDx-u18b9gH-yh0-wh7P6Av2BVBU2zodE/edit#gid=126332639',
  columns: [...locationConfig.columns, ...partisanColumnAppends],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, [
      {
        title: 'Default Filter',
        visibleColumns: [
          'turf_name',
          'location',
          'street_address',
          'county',
          'total_shifts',
          'days_since_last_canvass',
          'total_forms_cover',
          'total_field_hours_cover',
          'forms_per_field_hour_cover',
          'target_partisan_two_way_percent',
          'total_district_target_forms',
          'target_district_form_percent',
          'percent_pcs_on_qualifying_forms',
          'pcs_on_qualifying_forms',
          'forms_qualifying_for_pc',
          'shifts_under_threshold',
        ],
      },
    ])
  },
}
