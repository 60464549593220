import { useEffect, useState } from 'react'
import {
  TextBlock,
  ButtonBlock,
  Button,
  FieldBlock,
  DateField,
  SelectField,
  Font,
  TextArea,
} from '@politechdev/blocks-design-system'
import { useCurrent } from 'contexts'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import { fetchUsers } from 'requests/users'
import { fetchContactLog, postContactLog } from 'requests/people'
import { LoadBar } from 'components'
import ActivityLogTable from './ActivityLogTable'
import { ACTIVITY_TYPE_OPTIONS } from './constants'

const ActivityLog = ({ personId }) => {
  const { doesCurrentUserHavePermission } = useCurrent()

  const hasViewUsersPermissions = doesCurrentUserHavePermission({
    resource: 'user',
    ability: 'view',
  })

  const [showTable, setShowTable] = useState(true)
  const [tableData, setTableData] = useState()
  const [formData, setFormData] = useState({
    contact_type: null,
    contacted_at: new Date(),
    user_id: null,
    notes: null,
  })

  const { t } = useTranslation()

  // eslint-disable-next-line blocks/missing-response-error
  const fetchContactLogRequest = useRequest(fetchContactLog, {
    onSuccess: ({ contacts }) => {
      setTableData(contacts)
    },
  })

  // eslint-disable-next-line blocks/missing-response-error
  const postContactLogRequest = useRequest(
    () => postContactLog(personId, formData),
    {
      onSuccess: () => {
        setFormData({
          contact_type: null,
          contacted_at: new Date(),
          user_id: null,
          notes: null,
        })
        setShowTable(true)
        fetchContactLogRequest.makeRequest(personId, {
          fields: [
            'contacted_at',
            'contact_type',
            'notes',
            { user: 'name' },
            'meta',
          ],
        })
      },
    }
  )

  // eslint-disable-next-line blocks/missing-response-error
  const { makeRequest: usersRequest, response: usersResponse } =
    useRequest(fetchUsers)

  const userOptions = usersResponse
    ? usersResponse.users.map(user => ({ label: user.name, value: user.id }))
    : []

  useEffect(() => {
    fetchContactLogRequest.makeRequest(personId, {
      fields: [
        'contacted_at',
        'contact_type',
        'notes',
        { user: 'name' },
        'meta',
      ],
    })
  }, [])

  useEffect(() => {
    hasViewUsersPermissions &&
      usersRequest({ pageSize: Number.MAX_SAFE_INTEGER })
  }, [hasViewUsersPermissions])

  return (
    <div>
      <TextBlock>
        <Font.Copy variant="highlight">{t('Activity log')}</Font.Copy>
      </TextBlock>
      {showTable ? (
        <div>
          <ActivityLogTable
            log={tableData}
            loading={fetchContactLogRequest.isLoading}
          />
          {hasViewUsersPermissions && (
            <ButtonBlock>
              <Button
                onClick={() => {
                  setFormData({
                    contact_type: null,
                    contacted_at: new Date(),
                    user_id: null,
                    notes: null,
                  })
                  setShowTable(false)
                }}
              >
                {t('Log activity')}
              </Button>
            </ButtonBlock>
          )}
        </div>
      ) : (
        <div>
          <TextBlock>
            <Font.Label>{t('Log a new activity')}</Font.Label>
          </TextBlock>
          <LoadBar show={postContactLogRequest.isLoading} />
          <form>
            <FieldBlock>
              <SelectField
                id="activity-type"
                label={t('Activity type')}
                options={ACTIVITY_TYPE_OPTIONS}
                value={formData.contact_type}
                onSelect={contact_type =>
                  setFormData(form => ({ ...form, contact_type }))
                }
              />
            </FieldBlock>
            <FieldBlock>
              <DateField
                id="contact-date"
                label={t('Contact date')}
                value={new Date(formData.contacted_at).toISOString()}
                onChange={contacted_at =>
                  setFormData(form => ({ ...form, contacted_at }))
                }
              />
            </FieldBlock>
            <FieldBlock>
              <SelectField
                id="with"
                label={t('With')}
                options={userOptions}
                value={formData.user_id}
                onSelect={user_id =>
                  setFormData(form => ({ ...form, user_id }))
                }
              />
            </FieldBlock>
            <FieldBlock>
              <TextArea
                id="notes"
                label={t('Notes')}
                value={formData.notes}
                onChange={notes => setFormData(form => ({ ...form, notes }))}
              />
            </FieldBlock>
            <ButtonBlock>
              <Button.Accent
                onClick={postContactLogRequest.makeRequest}
                disabled={postContactLogRequest.isLoading}
              >
                {t('Save')}
              </Button.Accent>
              <Button.Secondary onClick={() => setShowTable(true)}>
                {t('Cancel')}
              </Button.Secondary>
            </ButtonBlock>
          </form>
        </div>
      )}
    </div>
  )
}

export default ActivityLog
