import { memo } from 'react'
import classNames from 'classnames/bind'
import { FormattedData, StaticListItem } from 'components'
import { QC_STATUS_MAP } from 'constants/qualityControl'
import styles from './FormViewer.module.scss'
import { useDeliveryActions, useDeliveryState } from '../DeliveryContext'

const PacketListItem = ({ shift, active }) => {
  const { eligibleFormIds } = useDeliveryState()
  const { makePacketRequest } = useDeliveryActions()
  const cx = classNames.bind(styles)
  const { name: filename, forms } = shift
  const eligibleCount = forms.filter(({ id }) =>
    eligibleFormIds.includes(id)
  ).length
  const potentialForms = forms.filter(
    forms =>
      !forms.visual_review_responses.some(
        response => response.implies_not_form === true
      )
  )
  const countMessage = `${eligibleCount} of ${potentialForms.length} forms eligible`
  const shiftStatusMessage = QC_STATUS_MAP[shift.status]
  const handleSelect = () => {
    makePacketRequest(shift.id)
  }

  return (
    <StaticListItem
      active={active}
      render={() => (
        <div
          className={`scroll-list__item ${
            active ? 'scroll-list__item--active' : ''
          }`}
          role="button"
          tabIndex={0}
          onClick={handleSelect}
          onKeyDown={e => e.key === 'Enter' && handleSelect()}
        >
          <div className="scroll-list__item--primary">{filename}</div>
          <div className="scroll-list__item--secondary">{countMessage}</div>
          <div className={cx('scroll-list__item--date-status')}>
            <div className="scroll-list__item--secondary">
              <FormattedData type="date" value={shift.shift_start} />{' '}
            </div>
            <div className="scroll-list__item--secondary">
              <span>{shiftStatusMessage}</span>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default memo(PacketListItem)
