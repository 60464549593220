export const personFields = {
  fields: [
    'id',
    'ethnicities',
    'age',
    'birth_date',
    'gender',
    'denominations',
    { turf: ['activism_config'] },
    {
      primary_relationships: [
        'id',
        'type',
        { first_person: ['id', 'name'] },
        { second_person: ['id', 'name'] },
      ],
    },
    {
      secondary_relationships: [
        'id',
        'type',
        { first_person: ['id', 'name'] },
        { second_person: ['id', 'name'] },
      ],
    },
    'primary_language',
    'languages',
    'custom_field_data',
  ],
}
