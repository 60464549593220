export const STATUS_TYPES = [
  {
    value: 'ready_for_qc',
    label: 'Ready for Visual QC',
  },
  {
    value: 'in_qc',
    label: 'In Visual QC',
  },
  {
    value: 'ready_for_phone_verification',
    label: 'Ready for Phone Verification',
  },
  {
    value: 'in_phone_verification',
    label: 'In Phone Verification',
  },
  {
    value: 'completed',
    label: 'Completed',
  },
] as const

export const qcPacketFields = [
  'id',
  'name',
  'forms_count',
  'qc_deadline',
  'status',
  'completed_at',
  'qc_external',
  'shift_end',
  {
    turf: [
      'name',
      'phone_verification_language',
      {
        visual_review_responses: [
          'id',
          'description',
          'response',
          'active',
          'grouping_metadata',
          'reviewable_type',
        ],
      },
    ],
  },
  {
    forms: [
      'visual_review_approved',
      {
        visual_reviews: [
          'id',
          'created_at',
          {
            response: ['response', 'implies_skips_phone_verification'],
          },
          {
            user: ['id', 'name'],
          },
        ],
      },
    ],
  },
  { creator: 'time_zone' },
  { location: ['state', 'timezone'] },
  { unresolved_flags: [{ trigger: ['stops_qc', 'needs_reupload'] }] },
] as const
export const REGISTRATION_TYPE = 'registration_scan'
export const ALL_PACKETS_TYPE = 'allPackets'
export const INDIVIDUAL_PACKETS_TYPE = 'individualPackets'

export const VISUAL_QC_TAB = 'VISUAL_QC_TAB'
export const PHONE_VERIFICATION_TAB = 'PHONE_VERIFICATION_TAB'
export const COMPLETED_TAB = 'COMPLETED_TAB'

export const READY_FOR_QC = 'ready_for_qc'
export const IN_QC = 'in_qc'
export const READY_FOR_PHONE_VERIFICATION = 'ready_for_phone_verification'
export const IN_PHONE_VERIFICATION = 'in_phone_verification'
export const READY_FOR_DATA_ENTRY = 'ready_for_data_entry'
export const IN_DATA_ENTRY = 'in_data_entry'
export const COMPLETED = 'completed'
export const PETITIONS_COMPLETED = READY_FOR_PHONE_VERIFICATION
export const QC_STATUSES = [
  'ready_for_qc',
  'in_qc',
  'ready_for_phone_verification',
  'in_phone_verification',
  'completed',
] as const

export type QCStatus = (typeof QC_STATUSES)[number]

export const QC_STATUS_MAP = {
  ready_for_qc: 'Ready for Visual QC',
  in_qc: 'In Visual QC',
  ready_for_phone_verification: 'Ready for Phone Verification',
  in_phone_verification: 'In Phone Verification',
  completed: 'QC Completed',
} as const

export const USER_TYPES = {
  STAFF: 'staff',
  MANAGER: 'manager',
} as const

export const STAFF_HOUR_OPTIONS = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
] as const

export const STAFF_MINUTE_OPTIONS = [
  { label: '0', value: 0 },
  { label: '15', value: 15 },
  { label: '30', value: 30 },
  { label: '45', value: 45 },
] as const
