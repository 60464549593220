import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { merge } from 'lodash'
import {
  CardError,
  Checkbox,
  LoadBar,
  StateSelectField,
  ViewMoreButton,
} from 'components'
import {
  Button,
  ButtonBlock,
  ContentBlock,
  FieldBlock,
  Sheet,
  Section,
  TextField,
  TextBlock,
  Font,
  useToast,
  SelectField,
  DateField,
} from '@politechdev/blocks-design-system'
import moment from 'moment'
import { maskPhone } from 'utils/inputMasks'
import { useReactRouter, useRequest } from 'hooks'
import { mergeImportError } from 'requests/duplicatePrevention'
import { getExtraFields } from 'duplicatePrevention/record/utils'
import { useCurrent } from 'contexts'
import { getDifferences, createRecordAttributeResolver } from './utils'
import { RecordContext } from '../RecordContext/RecordContext'
import RecordMergeDialog from '../RecordMergeDialog/RecordMergeDialog'
import DiffIndicator from '../FieldIndicator/DiffIndicator'

const RecordDuplicate = ({ personRecord }) => {
  const { match } = useReactRouter()
  const { setToast } = useToast()

  const {
    importRecord,
    resolvedFields,
    setResolvedFields,
    hideConfirmMerge,
    fetchErrorRowsRequest,
    row,
    setRow,
  } = useContext(RecordContext)

  const { currentTurfActivismLanguages } = useCurrent()

  const [isOpen, setIsOpen] = useState(false)

  const personPollAttributes = {
    polladdress1: personRecord?.polling_info?.address,
    polldescription1: personRecord?.polling_info?.description?.[0],
    polldescription2: personRecord?.polling_info?.description?.[1],
  }

  const differences = getDifferences(
    merge({}, importRecord, resolvedFields),
    merge({}, personRecord, personPollAttributes)
  )

  const { t } = useTranslation()

  const onSuccess = () => {
    setToast({
      message: t('Success'),
      variant: 'success',
    })

    const timeout = setTimeout(() => {
      clearTimeout(timeout)
      fetchErrorRowsRequest.makeRequest()
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
      if (row === 0) {
        setRow(1)
      }
    }, 1500)
  }

  const mergeImportRequest = useRequest(mergeImportError, { onSuccess })

  const extraFields = getExtraFields(importRecord, {
    ...personRecord,
    ...personPollAttributes,
  })

  const birthDateRecordVal = useMemo(() => {
    if (resolvedFields.birth_date !== undefined) {
      return resolvedFields.birth_date
    }
    return personRecord.birth_date
      ? moment(personRecord.birth_date).format('MM/DD/YYYY')
      : undefined
  }, [personRecord, resolvedFields])

  const fullNameValue = [
    personRecord.first_name,
    personRecord.middle_name,
    personRecord.last_name,
  ]
    .filter(x => !!x)
    .join(' ')

  const doesNameDiffer =
    differences.first_name || differences.middle_name || differences.last_name

  const fullAddressValue = [
    [
      personRecord.residential_address?.line_one,
      personRecord.residential_address?.line_two,
    ]
      .filter(x => !!x)
      .join(', '),
    [personRecord.residential_address?.city],
    [personRecord.residential_address?.county],
    [
      personRecord.residential_address?.state,
      personRecord.residential_address?.zipcode,
    ]
      .filter(x => !!x)
      .join(', '),
  ]
    .filter(x => !!x)
    .join(', ')

  const doesAddressDiffer =
    differences.residential_address?.line_one ||
    differences.residential_address?.line_two ||
    differences.residential_address?.county ||
    differences.residential_address?.city ||
    differences.residential_address?.state ||
    differences.residential_address?.zipcode

  const getDisplayableValue = createRecordAttributeResolver(
    personRecord,
    resolvedFields,
    importRecord
  )

  return (
    <div>
      {isOpen && !hideConfirmMerge && (
        <RecordMergeDialog
          personRecord={personRecord}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
      <ButtonBlock collapsed>
        <Button
          onClick={() => {
            hideConfirmMerge
              ? mergeImportRequest.makeRequest(
                  match.params.importId,
                  importRecord.id,
                  {
                    target_id: personRecord.id,
                    row_data: merge(
                      {
                        residential_address: personRecord.residential_address,
                      },
                      importRecord,
                      resolvedFields,
                      {
                        birth_date: moment(
                          resolvedFields.birth_date || importRecord.birth_date
                        ).format('YYYY-MM-DD'),
                      }
                    ),
                  }
                )
              : setIsOpen(true)
          }}
        >
          {t('Merge here')}
        </Button>
      </ButtonBlock>
      <ContentBlock>
        <Sheet>
          <LoadBar show={mergeImportRequest.isLoading} />
          <CardError
            hide={!mergeImportRequest.hasErrors}
            message={t('Something went wrong merging this record.')}
          />
          <Section label={t('Name')}>
            <DiffIndicator
              value={fullNameValue}
              hasDifference={doesNameDiffer}
            />
            <FieldBlock>
              <TextField
                label={t('First name')}
                value={getDisplayableValue('first_name')}
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    first_name: val,
                  }))
                }
                required
              />
              <TextField
                label={t('Middle name')}
                value={getDisplayableValue('middle_name')}
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    middle_name: val,
                  }))
                }
              />
              <TextField
                label={t('Last name')}
                value={getDisplayableValue('last_name')}
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    last_name: val,
                  }))
                }
                required
              />
            </FieldBlock>
          </Section>
          <Section label={t('Primary phone number')}>
            <DiffIndicator
              value={maskPhone(personRecord.primary_phone_number)}
              hasDifference={differences.primary_phone_number}
            />
            <FieldBlock>
              <TextField
                type="tel"
                value={getDisplayableValue('primary_phone_number')}
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    primary_phone_number: val,
                  }))
                }
              />
            </FieldBlock>
          </Section>
          <Section label={t('Receives sms')}>
            <DiffIndicator
              value={personRecord.receives_sms}
              hasDifference={differences.receives_sms}
            />
            <FieldBlock>
              <Checkbox
                label={t('Receives sms')}
                checked={getDisplayableValue('receives_sms')}
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    receives_sms: val,
                  }))
                }
              />
            </FieldBlock>
          </Section>
          <Section label={t('Primary email')}>
            <DiffIndicator
              value={personRecord.primary_email_address}
              hasDifference={differences.primary_email_address}
            />
            <FieldBlock>
              <TextField
                value={getDisplayableValue('primary_email_address')}
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    primary_email_address: val,
                  }))
                }
              />
            </FieldBlock>
          </Section>
          <Section label={t('Primary language')}>
            <DiffIndicator
              value={personRecord.primary_language}
              hasDifference={differences.primary_language}
            />
            <FieldBlock>
              <SelectField
                value={getDisplayableValue('primary_language')}
                onSelect={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    primary_language: val,
                  }))
                }
                options={currentTurfActivismLanguages.map(language => ({
                  label: language,
                  value: language,
                }))}
              />
            </FieldBlock>
          </Section>
          <Section label="Address">
            <DiffIndicator
              value={fullAddressValue}
              hasDifference={doesAddressDiffer}
            />
            <FieldBlock>
              <TextField
                label={t('Address line 1')}
                value={getDisplayableValue('residential_address.line_one')}
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      line_one: val,
                    },
                  }))
                }
              />
              <TextField
                label={t('Address line 2')}
                value={getDisplayableValue('residential_address.line_two')}
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      line_two: val,
                    },
                  }))
                }
              />
              <TextField
                label={t('City')}
                value={getDisplayableValue('residential_address.city')}
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      city: val,
                    },
                  }))
                }
              />
              <TextField
                label={t('County')}
                value={getDisplayableValue('residential_address.county')}
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      county: val,
                    },
                  }))
                }
              />
              <StateSelectField
                label={t('State')}
                state={getDisplayableValue('residential_address.state')}
                onSelect={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      state: val,
                    },
                  }))
                }
              />
              <TextField
                label={t('Zipcode')}
                value={getDisplayableValue('residential_address.zipcode')}
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    residential_address: {
                      ...oldResolvedFields.residential_address,
                      zipcode: val,
                    },
                  }))
                }
                error={
                  getDisplayableValue('residential_address.zipcode') &&
                  !getDisplayableValue('residential_address.zipcode').match(
                    /^\d{5}(-\d{4})?$/
                  )
                }
                errorMessage={t('Invalid zip code')}
              />
            </FieldBlock>
          </Section>
          <Section label={t('Date of birth')}>
            <DiffIndicator
              value={moment(personRecord.birth_date).format('MM/DD/YYYY')}
              hasDifference={differences.birth_date}
            />
            <FieldBlock>
              <DateField
                value={birthDateRecordVal}
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    birth_date: val,
                  }))
                }
              />
            </FieldBlock>
          </Section>
          <Section label={t('External ID')}>
            <DiffIndicator
              value={personRecord.external_id}
              hasDifference={differences.external_id}
            />
            <FieldBlock>
              <TextField
                value={getDisplayableValue('external_id')}
                onChange={val =>
                  setResolvedFields(oldResolvedFields => ({
                    ...oldResolvedFields,
                    external_id: val,
                  }))
                }
              />
            </FieldBlock>
          </Section>
          {!!extraFields.length && (
            <ViewMoreButton
              count={extraFields.length}
              name="field"
              isOpenByDefault={false}
            >
              {extraFields.map(
                field =>
                  field.value && (
                    <TextBlock>
                      <Font.Label>{t(field.label)}</Font.Label>
                      <Font.Copy>{field.value}</Font.Copy>
                    </TextBlock>
                  )
              )}
            </ViewMoreButton>
          )}
        </Sheet>
      </ContentBlock>
    </div>
  )
}

export default RecordDuplicate
