import React from 'react'
import { Reject, CheckCircle } from 'components/uiKit/icons'
import classNames from 'classnames/bind'
import { useQueryParams } from 'contexts'
import styles from './PageIndicator.module.scss'

const cx = classNames.bind(styles)

const statusIcon = approved => {
  if (approved) {
    return <CheckCircle className={styles['icon--approved']} />
  }

  if (approved === false) {
    return <Reject className={styles['icon--rejected']} />
  }
}

const PageIndicator = ({ isActive, approved, showStatusIcons, scan }) => {
  const { setQueryParams } = useQueryParams()

  return (
    <div
      className={styles.container}
      onClick={() => setQueryParams({ scanId: scan.id })}
    >
      <div
        className={cx('bubble', {
          'bubble--active': isActive,
        })}
      >
        <div>{scan.scan_number}</div>
      </div>
      {!!showStatusIcons && statusIcon(approved)}
    </div>
  )
}

export default PageIndicator
