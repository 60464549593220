import { fetchJSON, stringifyParams } from 'utils/req'
import {
  NOT_CONTACTED_RESPONSES,
  QuestionKey,
} from 'constants/qcCallScriptsConfig/phoneVerificationQuestions'
import { scanFields } from 'requests/scans'
import { Packet, Scan } from './types'

const shiftFields = [
  'id',
  'forms_count',
  'ready_for_qc_at',
  'qc_deadline',
  'name',
  { cover_sheet: 'file_url' },
  'status',
  'shift_start',
  'notes',
  { canvasser: ['first_name', 'last_name'] },
  {
    forms: [
      'id',
      'county',
      'delivery_id',
      'scan_number',
      'file_url',
      'file_locator',
      'display_name',
      'pledge_card_url',
      'notes',
      'visual_review_approved',
      'reviewed_by_user_id',
      {
        visual_reviews: [
          'id',
          {
            response: [
              'id',
              'description',
              'response',
              'implies_not_form',
              'implies_skips_phone_verification',
            ],
          },
          { user: ['full_name'] },
        ],
      },
      {
        phone_verification_responses: [
          'response',
          'phone_verification_question_id',
          'round_number',
        ],
      },
      { flags: ['status'] },
      { shift: ['id'] },
    ],
  },
  {
    unresolved_flag_triggers: [
      'id',
      'name',
      'resource_type',
      'default_action_plan',
      'needs_reupload',
      'implies_canvasser_issue',
    ],
  },
  { assignee: ['email'] },
  { location: ['state', 'name', 'timezone'] },
  { creator: 'time_zone' },
  {
    turf: [
      'id',
      'name',
      'phone_verification_language',
      'voter_registration_config',
      { phone_verification_scripts: ['language', 'structure'] },
      {
        visual_review_responses: [
          'id',
          'description',
          'response',
          'active',
          'grouping_metadata',
          'reviewable_type',
        ],
      },
      { phone_verification_questions: ['question', 'id'] },
    ],
  },
]

export const fetchPacket = (shiftId: number, opts = {}) =>
  fetchJSON(
    `/api/v1/shifts/${shiftId}?${stringifyParams({
      fields: shiftFields,
    })}`,
    'GET',
    undefined,
    { useJwt: true, ...opts }
  ).then(({ shift }) => shift as Packet)

export const fetchScan = async (scanId: number, opts = {}) => {
  const baseUrl = `/api/v1/forms/${scanId}?${stringifyParams(scanFields)}`
  const { registration_form: fetchedScan } = await fetchJSON<{
    registration_form: Scan
  }>(baseUrl, 'GET', undefined, { useJwt: true }, opts)

  fetchedScan.visual_reviews = fetchedScan.visual_reviews.map(vrr => ({
    ...vrr,
    user: vrr.user
      ? { ...vrr.user, name: vrr.user.name, full_name: vrr.user.name }
      : vrr.user,
  }))

  fetchedScan.phone_verification_responses =
    fetchedScan.phone_verification_responses.map(({ response, ...rest }) => ({
      ...rest,
      response,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'contacted?': !Object.keys(NOT_CONTACTED_RESPONSES).includes(response),
    }))

  return fetchedScan
}

export const redoVisualQc = (scan: { id: number }) =>
  fetchJSON(`/api/v1/forms/${scan.id}/redo_visual_qc`, 'PATCH', undefined, {
    useJwt: true,
  })

export const fetchPhoneVerificationQuestions = async (
  turfId: number,
  opts = {}
) => {
  const json = await fetchJSON<{
    turf: {
      phone_verification_questions: Array<{ id: number; question: QuestionKey }>
    }
  }>(
    `/api/v1/turfs/${turfId}?${stringifyParams({
      fields: [{ phone_verification_questions: ['id', 'question'] }],
    })}`,
    'GET',
    null,
    { useJwt: true },
    opts
  )

  return json.turf.phone_verification_questions
}
