import { calculatePercent, summaryFunctions } from 'utils/reporting'

export const partisanColumnAppends = [
  {
    dataKey: 'total_partisan_target_forms',
    title: 'Total Partisan Target Forms',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'target_partisan_two_way_percent',
    title: 'Partisan Forms Two Way Percent',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.total_partisan_target_forms,
        rowData.total_two_way_party_forms
      ),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customPercent(data, acc, [
        'total_partisan_target_forms',
        'total_two_way_party_forms',
      ]),
  },
  {
    dataKey: 'total_district_target_forms',
    title: 'Total District Target Forms',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'target_district_form_percent',
    title: 'District Target Forms Percent',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.total_district_target_forms,
        rowData.total_scans_qc
          ? rowData.total_scans_qc
          : rowData.complete_scans_qc + rowData.incomplete_scans_qc
      ),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customPercent(data, acc, [
        'total_district_target_forms',
        'total_scans_qc',
      ]),
  },
  {
    dataKey: 'pcs_on_qualifying_forms',
    title: 'Total Pledge Cards for Qualifying Forms',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'forms_qualifying_for_pc',
    title: 'Total Qualifying Forms',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'percent_pcs_on_qualifying_forms',
    title: 'Percent of Qualifying Forms with Pledge Cards',
    type: 'number',
    resizable: true,
    deriveFunction: ({ rowData }) =>
      calculatePercent(
        rowData.pcs_on_qualifying_forms,
        rowData.forms_qualifying_for_pc
      ),
    summaryFunction: ({ data, acc }) =>
      summaryFunctions.customPercent(data, acc, [
        'pcs_on_qualifying_forms',
        'forms_qualifying_for_pc',
      ]),
  },
  {
    dataKey: 'shifts_under_threshold',
    title: 'Total Shifts Under Pledge Card Threshold',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'registered_democratic_count',
    title: 'Registered Democratic (Cover)',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'registered_none_count',
    title: 'Registered None (Cover)',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'pledge_card_count',
    title: 'Pledge Cards (Cover)',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
  {
    dataKey: 'pledge_democrat_count',
    title: 'Lean or Strong Democrat (Cover)',
    type: 'number',
    resizable: true,
    summaryFunction: summaryFunctions.sum,
  },
]
