import { buildDefaultFilters } from 'utils/reporting'
import { partisanColumnAppends } from 'registrationReports/reportsConfig/columns/partisanColumnAppends'
import canvasserQualityConfig from './canvasserQuality'

export default {
  id: 'canvasser_quality_party',
  name: 'Field (partisan)',
  permissionId: 'cover_sheet_party_report',
  description:
    'This report compares total forms from the cover sheet to total forms from the scans and counts from the visual QC process by canvasser. The data is broken out by both raw totals and percentages.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1xABMR2DqVMaDx-u18b9gH-yh0-wh7P6Av2BVBU2zodE/edit#gid=663985049',
  columns: [...canvasserQualityConfig.columns, ...partisanColumnAppends],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, [
      {
        title: 'Canvass Information',
        visibleColumns: [
          'canvasser_name',
          'active_canvasser',
          'last_canvass_date',
          'total_scans_cover',
          'total_field_hours_cover',
          'scans_per_field_hour_cover',
          'total_shift_hours',
          'complete_scan_percent_qc',
          'percent_with_phones_qc',
          'target_partisan_two_way_percent',
          'total_district_target_forms',
          'target_district_form_percent',
          'percent_pcs_on_qualifying_forms',
          'pcs_on_qualifying_forms',
          'forms_qualifying_for_pc',
          'shifts_under_threshold',
        ],
      },
    ])
  },
}
