import { cloneDeep } from 'lodash'
import { fetchImportError } from 'requests/duplicatePrevention'

export const getExtraFields = importRecord => {
  const extraFields = [
    {
      label: 'Prefix',
      value: importRecord.prefix,
    },
    {
      label: 'Suffix',
      value: importRecord.suffix_name,
    },
    {
      label: 'County',
      value: importRecord.residential_address?.county,
    },
    {
      label: 'Gender',
      value: importRecord.gender,
    },
    {
      label: 'GoTV phone number',
      value: importRecord.gotv_phone_number,
    },
    {
      label: 'Teams',
      value: importRecord.teams?.join(', '),
    },
    {
      label: 'Organizations',
      value: importRecord.organization_memberships?.join(', '),
    },
    {
      label: 'Events attended',
      value: importRecord.event_attendances?.join(', '),
    },
    {
      label: 'Campaigns',
      value: importRecord.campaigns?.join(', '),
    },
    {
      label: 'Issues',
      value: importRecord.issues?.join(', '),
    },
    {
      label: 'Notes',
      value: `Note 1: ${importRecord.notes
        ?.map(({ content }) => content)
        ?.join(' | Note 2: ')}`,
    },
  ]

  return extraFields
}

export const getImportRecord = errorRow =>
  errorRow ? { ...errorRow.row_data, id: errorRow.id } : null

export const getInvalidFields = errorRow => {
  if (!errorRow) return {}

  const invalidFields = {}

  Object.keys(errorRow.errors_triggered || []).forEach(key => {
    if (key === 'residential_address') {
      invalidFields.residential_address = {}
      Object.keys(errorRow.errors_triggered.residential_address).forEach(
        key => {
          invalidFields.residential_address[key] = {
            error: errorRow.errors_triggered.residential_address[key],
            value: errorRow.row_data.residential_address[key],
          }
        }
      )
      return
    }

    invalidFields[key] = {
      error: errorRow.errors_triggered[key],
      value: errorRow.row_data[key],
    }
  })

  return invalidFields
}

export const withoutInvalidFields = (importRecord, invalidFields) => {
  const copiedRecord = cloneDeep(importRecord)

  Object.keys(copiedRecord).forEach(key => {
    if (key === 'residential_address') {
      Object.keys(copiedRecord[key]).forEach(addressKey => {
        if (
          copiedRecord.residential_address[addressKey] === '' ||
          (invalidFields.residential_address &&
            invalidFields.residential_address[addressKey])
        ) {
          copiedRecord.residential_address[addressKey] = null
        }
      })
      return
    }

    if (key === 'notes' && (!copiedRecord.notes || invalidFields.notes)) {
      copiedRecord.notes = []
      return
    }

    if (copiedRecord[key] === '' || invalidFields[key]) {
      copiedRecord[key] = null
    }
  })

  return copiedRecord
}

function coerceReceivesSms(value) {
  if (value === 'true') {
    return true
  }
  if (value === 'false') {
    return false
  }
  return value
}

export const fetchErrorRow = (importId, recordId, params) =>
  fetchImportError(importId, recordId, params).then(response => {
    response['imports/error_row'].row_data.receives_sms = coerceReceivesSms(
      response['imports/error_row'].row_data.receives_sms
    )
    return response
  })
