import { Section, DetailItem } from 'components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { dateFormat } from 'utils/constants'
import {
  getFullCallHistory,
  getMostRecentVisualReview,
  getMostRecentVisualReviewResponses,
} from 'qualityControl/contexts/contextSelectors'
import { useCurrentScan } from 'qualityControl/contexts/ScanContext'
import { usePhoneVerificationQuestions } from 'qualityControl/contexts/PhoneVerificationQuestionsContext'
import CallHistoryList from '../CallHistoryList/CallHistoryList'

const ScanHistoryDetails = () => {
  const { t } = useTranslation()

  const [currentScan] = useCurrentScan()
  const [questions] = usePhoneVerificationQuestions()
  const callAttempts = getFullCallHistory(currentScan, questions)

  let mostRecentVisualReview = null
  let mostVisualReviewResponses = null
  if (currentScan.visual_review_approved) {
    mostRecentVisualReview = {
      created_at: currentScan.reviewed_at,
      user: currentScan.reviewed_by_user,
    }
    mostVisualReviewResponses = ['Approved']
  } else {
    mostRecentVisualReview = getMostRecentVisualReview(currentScan)
    mostVisualReviewResponses = getMostRecentVisualReviewResponses(currentScan)
  }

  return (
    <>
      {!!callAttempts.length && <CallHistoryList attempts={callAttempts} />}
      <Section label={t('Visual QC')}>
        <DetailItem label={t('Responses')}>
          {mostVisualReviewResponses?.length
            ? mostVisualReviewResponses.map(response => (
                <>
                  <span>{response}</span>
                  <br />
                </>
              ))
            : t('No response found')}
        </DetailItem>
        <DetailItem label={t('Created at')}>
          {(mostRecentVisualReview?.created_at &&
            moment(mostRecentVisualReview.created_at).format(dateFormat)) ||
            'No date found'}
        </DetailItem>
        <DetailItem label={t('Reviewed by')}>
          {mostRecentVisualReview?.user?.full_name ||
            t('No approving reviewer found')}
        </DetailItem>
        <DetailItem label={t('Visual QC Notes')}>
          {currentScan.notes || t('No notes found')}
        </DetailItem>
      </Section>
    </>
  )
}

export default ScanHistoryDetails
