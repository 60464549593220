import { createContext, useCallback, useEffect, useState } from 'react'
import { useToast } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash/function'
import { fetchImportErrors } from 'requests/duplicatePrevention'
import { fetchPeopleDuplicates } from 'requests/people'
import { useReactRouter, useRequest } from 'hooks'
import { unmaskPhone } from 'utils/inputMasks'
import {
  fetchErrorRow,
  getExtraFields,
  getImportRecord,
  getInvalidFields,
  withoutInvalidFields,
} from './utils'
import { PER } from '../utils'

export const RecordContext = createContext()

const RecordContextProvider = ({ children }) => {
  const { match, history } = useReactRouter()
  const { t } = useTranslation()

  const { recordId, importId } = match.params

  const { setToast } = useToast()

  const [row, setRow] = useState(0)
  const [resolvedFields, setResolvedFields] = useState({})
  const [hideConfirmMerge, setHideConfirmMerge] = useState(false)
  const [recordCount, setRecordCount] = useState(undefined)

  const DUPLICATE_REQ_BASE_PARAMS = {
    fields: [
      'id',
      'first_name',
      'middle_name',
      'last_name',
      'prefix',
      'suffix_name',
      'primary_phone_number',
      'primary_email_address',
      'polling_info',
      'birth_date',
      'state',
      'external_id',
      'receives_sms',
      { attended_events: ['id'] },
      { teams: ['id'] },
      { organizations: ['id'] },
      {
        residential_address: [
          'line_one',
          'line_two',
          'city',
          'state',
          'zipcode',
          'county',
        ],
      },
    ],
  }

  const fetchErrorRowsRequest = useRequest(
    params =>
      fetchImportErrors(importId, {
        ...params,
        per: PER,
        fields: ['id', 'row_data', 'duplicate_found', 'errors_triggered'],
      }),
    {
      onSuccess: response => {
        setRecordCount(response.meta.total_count)
        const errorRows = response['imports/error_rows']
        if (errorRows && errorRows.length === 0) {
          history.push(
            `/data_sources/imports/${importId}/duplicate_prevention/done`
          )
        }
      },
      onError: () => {
        setToast({
          message: t('An issue occured while fetching error rows'),
          variant: 'error',
        })
      },
    }
  )

  const {
    makeRequest: duplicateReq,
    response: { people: duplicateRes } = {},
    isLoading: duplicateReqLoading,
  } = useRequest(fetchPeopleDuplicates, {
    onError: () => {
      setToast({
        message: t('An issue occured while checking for possible duplicates'),
        variant: 'error',
      })
    },
  })

  const fetchImportDataRequest = useRequest(
    id =>
      fetchErrorRow(importId, id, {
        fields: ['id', 'row_data', 'errors_triggered', 'index'],
      }),
    {
      onSuccess: result => {
        const {
          'imports/error_row': { index, row_data },
        } = result
        fetchErrorRowsRequest.makeRequest()
        setResolvedFields({})
        setRow(index)
        duplicateReq({
          ...DUPLICATE_REQ_BASE_PARAMS,
          attrs: {
            first_name: row_data.first_name,
            last_name: row_data.last_name,
            primary_email_address: row_data.primary_email_address,
            primary_phone_number: row_data.primary_phone_number,
            external_id: row_data.external_id,
          },
        })
      },
      onError: () => {
        setToast({
          message: t('An issue occured while fetching import data'),
          variant: 'error',
        })
      },
    }
  )

  useEffect(() => {
    recordId && fetchImportDataRequest.makeRequest(recordId)
  }, [recordId])

  const errorRow = fetchImportDataRequest.response?.['imports/error_row']

  const invalidFields = getInvalidFields(errorRow)

  const importRowData = getImportRecord(errorRow)

  const importRecord = importRowData
    ? withoutInvalidFields(importRowData, invalidFields)
    : null

  const extraFields = importRecord ? getExtraFields(importRecord) : []

  const reqDuplicateUpdate = useCallback(
    () =>
      debounce(
        fields =>
          duplicateReq({
            ...DUPLICATE_REQ_BASE_PARAMS,
            attrs: {
              first_name: importRecord?.first_name,
              last_name: importRecord?.last_name,
              primary_email_address: importRecord?.primary_email_address,
              primary_phone_number: unmaskPhone(
                importRecord?.primary_phone_number
              ),
              external_id: importRecord?.external_id,
              ...fields,
            },
          }),
        500
      ),
    [importRecord]
  )

  const hasExternalId =
    (importRecord?.external_id && resolvedFields?.external_id === undefined) ||
    resolvedFields.external_id

  const hasDuplicateWithSameExternalId =
    hasExternalId &&
    !!(duplicateRes || []).find(
      person =>
        (resolvedFields?.external_id &&
          person.external_id === resolvedFields.external_id) ||
        (!resolvedFields.external_id &&
          importRecord?.external_id &&
          person.external_id === importRecord?.external_id)
    )

  const handlePageChange = async delta => {
    const nextRow = row + delta

    let { response } = fetchErrorRowsRequest
    const wantedPage = Math.ceil(nextRow / PER)

    response = await fetchErrorRowsRequest.makeRequest({
      current_page: wantedPage,
    })

    const i = nextRow - (wantedPage - 1) * PER - 1
    const errorRow = response['imports/error_rows'][i]

    setRow(nextRow)
    history.replace(
      `/data_sources/imports/${importId}/duplicate_prevention/${errorRow.id}`
    )
  }

  return (
    <RecordContext.Provider
      value={{
        importRecord,
        row,
        resolvedFields,
        setResolvedFields,
        setRow,
        invalidFields,
        fetchErrorRowsRequest,
        duplicateRes,
        fetchImportDataRequest,
        duplicateReqLoading,
        extraFields,
        hideConfirmMerge,
        setHideConfirmMerge,
        reqDuplicateUpdate,
        hasDuplicateWithSameExternalId,
        recordCount,
        handlePageChange,
      }}
    >
      {children}
    </RecordContext.Provider>
  )
}

export default RecordContextProvider
