import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { MultiSearchSelect } from 'components'
import { useCurrent } from 'contexts/index'
import { buildTag, buildOption } from './utils'

const PersonTagSelectFieldDEPRECATED = ({
  label,
  context,
  onSelect,
  tags,
  ...props
}) => {
  const { t } = useTranslation()

  const [value, setValue] = useState(tags.map(buildOption))
  const {
    currentUser: {
      turf: { activism_config: activismOptions },
    },
  } = useCurrent()

  const options = [...((activismOptions || {})[context] || [])]
    .map(buildOption)
    .reduce(
      (options, option) =>
        options.find(({ value }) => value === option.value)
          ? options
          : [...options, option],
      []
    )

  useEffect(() => {
    setValue(tags.map(buildOption))
  }, [tags])

  const handleMultiChange = selections => {
    onSelect && onSelect(selections.map(buildTag))
  }

  return (
    <MultiSearchSelect
      label={label || t('Tags')}
      options={options}
      onChange={handleMultiChange}
      value={value || []}
      {...props}
    />
  )
}

PersonTagSelectFieldDEPRECATED.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  context: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}

PersonTagSelectFieldDEPRECATED.defaultProps = {
  id: 'tags',
  context: null,
  tags: [],
}

export default PersonTagSelectFieldDEPRECATED
