import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Button, ButtonBlock, FieldBlock } from 'components'
import { useAuth } from 'contexts'
import { useRequest, useRouteQueryParams } from 'hooks'
import {
  verifyResetToken as verifyTokenRequest,
  resetPassword as resetPasswordRequest,
} from 'requests/auth'
import AuthFrame from '../AuthFrame/AuthFrame'

const ChangePassword = ({ type }) => {
  const { t } = useTranslation()
  const [{ token }] = useRouteQueryParams()

  const isReset = type === 'reset'

  const { login } = useAuth()
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  const {
    makeRequest: verifyToken,
    isLoading: verifyLoading,
    hasErrors: verifyError,
    isRequestComplete: verifyComplete,
  } = useRequest(verifyTokenRequest)
  const verifySuccess = !verifyLoading && !verifyError && verifyComplete

  const {
    makeRequest: resetPassword,
    isLoading: resetLoading,
    hasErrors: hasResetErrors,
    errors: resetErrors,
  } = useRequest(resetPasswordRequest, {
    onSuccess: response => login(response.token),
  })

  useEffect(() => {
    verifyToken(token)
  }, [token])

  const getResetRequestError = () => {
    if (resetErrors.error?.includes('Failed to send two factor token')) {
      return t(
        'We were unable to deliver your two factor authentication code. Please try again or contact support.'
      )
    }
    return 'We were unable to reset your password. Please try again later.'
  }

  const isFormValid = () => password?.length > 5 && password === passwordConfirm

  const handleSubmit = e => {
    e.preventDefault()
    if (!isFormValid()) return

    resetPassword({ token, password, passwordConfirm })
  }

  return (
    <AuthFrame>
      <AuthFrame.Title>
        {isReset ? t('Reset your password') : t('Create your password')}
      </AuthFrame.Title>
      {verifySuccess && (
        <AuthFrame.Content>
          {t('Please enter and confirm your new password.')}
        </AuthFrame.Content>
      )}
      {verifyLoading && (
        <AuthFrame.Content>
          {isReset
            ? t('Verifying your password reset link...')
            : t('Verifying your password create link...')}
        </AuthFrame.Content>
      )}
      {verifyError && (
        <AuthFrame.Error>
          {isReset
            ? t(
                'Your reset token is either invalid or expired. Please try to reset your password again.'
              )
            : t(
                'Your password token is either invalid or expired. Please contact your administrator to receive a new link.'
              )}
        </AuthFrame.Error>
      )}
      {hasResetErrors && (
        <AuthFrame.Error>
          {isReset
            ? getResetRequestError()
            : t(
                'We were unable to create your password. Please try again later.'
              )}
        </AuthFrame.Error>
      )}
      {verifySuccess && (
        <form onSubmit={handleSubmit}>
          <FieldBlock>
            <TextField
              required
              id="password"
              label={t('Password')}
              type="password"
              value={password}
              onChange={setPassword}
              ignoreWhitespace
              error={!!password && password?.length < 6}
              errorText={t('Must be at least 6 characters long')}
            />
          </FieldBlock>
          <FieldBlock>
            <TextField
              required
              id="password_confirmation"
              label={t('Password confirmation')}
              type="password"
              value={passwordConfirm}
              onChange={setPasswordConfirm}
              ignoreWhitespace
              error={!!passwordConfirm && passwordConfirm !== password}
              errorText={t('Passwords do not match')}
            />
          </FieldBlock>
          <AuthFrame.Actions>
            <ButtonBlock justify="left">
              <Button raised primary type="submit">
                {resetLoading ? t('Saving password') : t('Save password')}
              </Button>
            </ButtonBlock>
          </AuthFrame.Actions>
        </form>
      )}
    </AuthFrame>
  )
}

export default ChangePassword
