import { Icon, TooltipContainer } from '@politechdev/blocks-design-system'
import styles from './FieldIndicator.module.scss'

const DiffIndicator = ({ value, hasDifference }) => {
  if (!hasDifference) return null

  return (
    <div className={styles.container}>
      <span className={styles['container__value--error']}>
        {value || 'empty'}
      </span>
      <TooltipContainer label="Changes to record">
        <Icon.ArrowDown className={styles.container__icon} />
      </TooltipContainer>
    </div>
  )
}

export default DiffIndicator
