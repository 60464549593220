import { fetchJSON, stringifyParams } from 'utils/req'

export const scanFields = {
  fields: [
    'id',
    'county',
    'created_at',
    'scan_number',
    'file_url',
    'file_locator',
    'notes',
    'first_name',
    'last_name',
    'phone_number',
    'voting_street_address_one',
    'voting_street_address_two',
    'voting_state',
    'voting_zipcode',
    'voting_city',
    'date_of_birth',
    'metadata',
    'pledge_card_metadata',
    'pledge_card_url',
    'lookup_code',
    'ocr_data',
    'visual_review_approved',
    'reviewed_at',
    { reviewed_by_user: 'full_name' },
    { shift: ['shift_start'] },
    {
      unresolved_flag_triggers: ['needs_reupload', 'stops_qc'],
    },
    {
      turf: [
        'id',
        'name',
        'min_phone_verification_rounds',
        'min_phone_verification_percent',
        'voter_registration_config',
      ],
    },
    {
      visual_reviews: [
        'id',
        'created_at',
        {
          response: [
            'id',
            'response',
            'description',
            'implies_not_form',
            'implies_skips_phone_verification',
          ],
        },
        {
          user: ['id', 'name'],
        },
      ],
    },
    {
      phone_verification_responses: [
        'id',
        'response',
        'notes',
        'round_number',
        'phone_verification_question_id',
        'created_at',
        { caller: ['id', 'name'] },
        { call: ['id', 'created_at', 'number'] },
      ],
    },
    {
      phone_verification_calls: ['id', 'created_at', 'number'],
    },
  ],
}

export const getAssignableScans = (params: unknown) =>
  fetchJSON(
    `/api/v1/forms/assignable_for_qc?${stringifyParams(params)}`,
    'GET',
    undefined,
    {
      useJwt: true,
    }
  )

export async function updateRegistrantInformation(
  { form: { id: formId } }: { form: { id: number } },
  registrantInformation: unknown
) {
  const endpoint = `/api/v1/forms/${formId}`

  const { registration_form } = await fetchJSON<{
    registration_form: unknown
  }>(
    `${endpoint}?${stringifyParams({
      fields: [
        'first_name',
        'last_name',
        'id',
        'county',
        'phone_number',
        'voting_street_address_one',
        'voting_street_address_two',
        'voting_state',
        'voting_zipcode',
        'voting_city',
        'date_of_birth',
        'metadata',
      ],
    })}`,
    'PUT',
    { form: registrantInformation },
    {
      useJwt: true,
    }
  )

  return registration_form
}
