import { sortBy, orderBy } from 'lodash'
import {
  ENGLISH,
  Language,
  NO_PHONE,
  QuestionsRecord,
  REGISTERED,
  requiredQuestionKeys,
} from 'constants/qcCallScriptsConfig/phoneVerificationQuestions'
import {
  defaultScriptStructures,
  ScriptStructure,
} from 'constants/qcCallScriptsConfig/defaultScripts'
import {
  formatAllAttempts,
  getCallNotes,
  getFailedCallsForResponse,
  countScanAttempts,
  calculatePhoneVerifiedScanCount,
  getIsScanExcludedByVisualReview,
  getIsScanExcludedByPreviousNotContactedResponse,
  isPhoneVerificationComplete,
} from 'qualityControl/contexts/contextUtils'
import { REGISTRATION_TYPE } from 'constants/qualityControl'
import { generateCategoriesForVRIssues } from 'qualityControl/utils'
import { Packet, PacketScan, Scan } from './types'

function createSelector<T, TS, TR>(
  getState: (arg: T) => TS,
  selector: (state: TS) => TR
) {
  return (arg: T) => selector(getState(arg))
}

const getCurrentPacket = (packet: Packet) => packet

export const getCurrentPacketTurfId = createSelector(
  getCurrentPacket,
  currentPacket => currentPacket.turf.id
)

export const getCurrentPacketVisualReviewResponses = createSelector(
  getCurrentPacket,
  currentPacket => currentPacket?.turf.visual_review_responses || []
)

type Positioned = { position: number }

type Category = Positioned & {
  subcategories: Record<string, Category>
  children: Array<Positioned>
}

const positionSort = (a: Positioned, b: Positioned) => a.position - b.position

export const getVisualReviewIssueOptions = createSelector(
  getCurrentPacketVisualReviewResponses,
  vrrs =>
    Object.values<Category>(
      vrrs
        .filter(vrr => vrr.active && vrr.reviewable_type === REGISTRATION_TYPE)
        .reduce<Record<string, Category>>(generateCategoriesForVRIssues, {})
    )
      .map(category => ({
        ...category,
        subcategories: Object.values(category.subcategories)
          .map(subcategory => ({
            ...subcategory,
            children: subcategory.children.sort(positionSort),
          }))
          .sort(positionSort),
        children: category.children.sort(positionSort),
      }))
      .sort(positionSort)
)

export const getCurrentVoterRegistrationScans = createSelector(
  getCurrentPacket,
  ({ forms }) =>
    forms.length ? sortBy(forms, 'scan_number') : <PacketScan[]>[]
)

export const getSortedScanIds = createSelector(
  getCurrentVoterRegistrationScans,
  (scans: PacketScan[]) => (scans.length ? scans.map(s => s.id) : [])
)

export const getVisualUnreviewedScans = createSelector(
  getCurrentVoterRegistrationScans,
  (scans: PacketScan[]) =>
    scans.filter(s => !s.visual_reviews.filter(vr => vr.user).length)
)

export const getVisualUnreviewedScanIds = createSelector(
  getVisualUnreviewedScans,
  (scans: PacketScan[]) => scans.map(s => s.id)
)

export const getVisualUnapprovedScans = createSelector(
  getCurrentVoterRegistrationScans,
  scans => scans?.filter(s => !s.visual_review_approved)
)

export const getVisualUnapprovedScanIds = createSelector(
  getVisualUnapprovedScans,
  (scans: PacketScan[]) => scans.map(s => s.id)
)

export const getCurrentPacketVisualQcComplete = createSelector(
  getCurrentVoterRegistrationScans,
  forms => forms.every(form => !!form.reviewed_by_user_id)
)

export const getContactedScansCount = createSelector(
  getCurrentVoterRegistrationScans,
  scans =>
    scans.reduce(
      (count, scan) =>
        scan.phone_verification_responses.some(r => r['contacted?'])
          ? count + 1
          : count,
      0
    )
)

export const getCurrentPacketShowPors = createSelector(
  getCurrentPacket,
  packet => packet.turf.voter_registration_config.show_pors
)

export const getCurrentPacketLocationState = createSelector(
  getCurrentPacket,
  packet => packet.location?.state
)

export const getCurrentPacketTurfLanguage = createSelector(
  getCurrentPacket,
  packet => packet.turf.language || ENGLISH
)

export const getCurrentPacketTurfScripts = createSelector(
  getCurrentPacket,
  packet => packet.turf.phone_verification_scripts || []
)

export const getPhoneUnreviewedScans = createSelector(
  getCurrentVoterRegistrationScans,
  (scans: PacketScan[]) =>
    scans.filter(s => !s.phone_verification_responses.length)
)

export const getPhoneUnreviewedScanIds = createSelector(
  getPhoneUnreviewedScans,
  (scans: PacketScan[]) => scans.map(s => s.id)
)

const getCurrentScan = (scan: Scan) => scan

export const getCurrentRegistrationForm = createSelector(
  getCurrentScan,
  currentScan => currentScan
)

export const getCurrentRegistrantCounty = createSelector(
  getCurrentRegistrationForm,
  regForm => regForm.county
)

export const getCurrentScanVisualReviews = createSelector(
  getCurrentScan,
  currentScan => {
    if (!currentScan.visual_reviews.length) {
      return {
        status: 'unreviewed',
        responses: [],
      }
    }

    return {
      status: currentScan.visual_review_approved ? 'approved' : 'issuesRaised',
      responses: currentScan.visual_reviews,
    }
  }
)

export const getMostRecentVisualReview = createSelector(
  getCurrentScanVisualReviews,
  ({ responses }) => orderBy(responses, 'created_at', 'desc')[0]
)

export const getMostRecentVisualReviewResponses = createSelector(
  getCurrentScanVisualReviews,
  ({ responses }) => responses.map(response => response.response.description)
)

export const getPhoneVerificationResponses = createSelector(
  getCurrentScan,
  scan => scan.phone_verification_responses
)

export const getPreviousCallAttemptCount = createSelector(
  getPhoneVerificationResponses,
  responses => countScanAttempts(responses)
)

export const getPhoneVerificationCalls = createSelector(
  getCurrentScan,
  currentScan => currentScan.phone_verification_calls
)

export const getVerifyingQuestionId = (questions: QuestionsRecord) =>
  questions[REGISTERED]?.id

export const getCurrentSessionScript = (
  packet: Packet,
  userSetLanguage: Language
) => {
  const turfLanguage = getCurrentPacketTurfLanguage(packet)
  const activeLanguage = userSetLanguage || turfLanguage
  const customScript = getCurrentPacketTurfScripts(packet).find(
    script => script?.language === activeLanguage
  )
  return (
    (customScript?.structure as ReadonlyArray<ScriptStructure>) ||
    defaultScriptStructures[activeLanguage]
  )
}

export const getAreAllPhoneVerificationQuestionsAnswered = (
  phoneVerificationQuestions: QuestionsRecord,
  packet: Packet,
  userSetLanguage: Language
) => {
  const currentSessionScript = getCurrentSessionScript(packet, userSetLanguage)
  requiredQuestionKeys
    .filter(questionKey =>
      currentSessionScript.find(
        scriptItem => scriptItem.questionKey === questionKey
      )
    )
    .every(questionKey => phoneVerificationQuestions[questionKey].response)
}

export const getFullCallHistory = (scan: Scan, questions: QuestionsRecord) => {
  const responses = getPhoneVerificationResponses(scan)
  const verifyingId = getVerifyingQuestionId(questions)
  const phoneVerificationCalls = getPhoneVerificationCalls(scan)
  if (responses.length === 0) {
    return []
  }
  return formatAllAttempts(responses, verifyingId).map(formattedResponse => ({
    ...formattedResponse,
    failedCalls: getFailedCallsForResponse(
      responses,
      formattedResponse.call,
      phoneVerificationCalls
    ),
    notes: getCallNotes(responses, formattedResponse.call),
  }))
}

export const getVerifiedScansCount = (
  packet: Packet,
  questionsRecord: QuestionsRecord
) =>
  calculatePhoneVerifiedScanCount(
    getCurrentVoterRegistrationScans(packet),
    getVerifyingQuestionId(questionsRecord)
  )

export const getScansWithPhoneNumbersVerifiedPercent = (
  packet: Packet,
  questionsRecord: QuestionsRecord
) => {
  const verifiedCount = getVerifiedScansCount(packet, questionsRecord)
  const scansWithPhone = getCurrentVoterRegistrationScans(packet).filter(
    scan => {
      const visualResponses = scan.visual_reviews.some(
        review =>
          review.response.implies_not_form ||
          review.response.implies_skips_phone_verification
      )

      const phoneResponses = scan.phone_verification_responses.some(
        phoneVerificationResponse =>
          phoneVerificationResponse.response === NO_PHONE
      )

      return !visualResponses && !phoneResponses
    }
  )

  if (!scansWithPhone.length) return 100
  return Math.round((verifiedCount / scansWithPhone.length) * 100)
}

export const getCallableScans = (
  packet: Packet,
  questionsRecord: QuestionsRecord
) => {
  const verifyingId = getVerifyingQuestionId(questionsRecord)
  return getCurrentVoterRegistrationScans(packet).filter(scan => {
    const contacted = scan.phone_verification_responses.find(
      r => r.phone_verification_question_id === verifyingId
    )

    const isCallable =
      !getIsScanExcludedByVisualReview(scan) &&
      !getIsScanExcludedByPreviousNotContactedResponse(scan)

    return !contacted && isCallable
  })
}

export const getCurrentPacketPhoneVerificationComplete = (
  packet: Packet,
  questionsRecord: QuestionsRecord,
  currentScan: Scan
) => {
  const scans = getCallableScans(packet, questionsRecord)
  const allScansVerifiedPercent = getScansWithPhoneNumbersVerifiedPercent(
    packet,
    questionsRecord
  )
  const {
    min_phone_verification_rounds: turfVerificationRounds,
    min_phone_verification_percent: TurfVerificationPercent,
  } = currentScan?.turf || {}

  return isPhoneVerificationComplete(
    scans,
    allScansVerifiedPercent,
    turfVerificationRounds,
    TurfVerificationPercent
  )
}
