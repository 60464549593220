import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CardError,
  OrganizationSelectField,
  ZipCodeField,
  StateSelectField,
} from 'components'
import {
  Button,
  FieldBlock,
  ButtonBlock,
  TextField,
  SelectField,
  Checkbox,
} from '@politechdev/blocks-design-system'
import { EventContext } from '../EventContext/EventContext'

const AddGuestModalForm = ({
  submitForm,
  formData,
  setField,
  toggleForm,
  isFormValid,
  modalIsLoading,
  createPersonError,
  setDuplicateFound,
}) => {
  const { t } = useTranslation()
  const { currentEvent } = useContext(EventContext)
  const languages = currentEvent?.turf.activism_config?.languages ?? []

  return (
    <div>
      <form onSubmit={submitForm}>
        <FieldBlock>
          <TextField
            id="guestFirstName"
            label={t('First name')}
            value={formData.first_name || ''}
            onChange={val => setField(val, 'first_name')}
            required
          />
          <TextField
            id="guestLastName"
            label={t('Last name')}
            value={formData.last_name || ''}
            onChange={val => setField(val, 'last_name')}
            required
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            type="email"
            id="email"
            label={t('Email')}
            value={formData.email || ''}
            onChange={val => {
              setDuplicateFound(false)
              setField(val, 'email')
            }}
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            type="tel"
            id="phone"
            label={t('Phone number')}
            value={formData.phone || ''}
            onChange={val => {
              setDuplicateFound(false)
              setField(val, 'phone')
            }}
          />
        </FieldBlock>
        <FieldBlock collapsed>
          <Checkbox
            id="person-sms"
            name="add-sms"
            label={t('Receives SMS')}
            type="checkbox"
            checked={formData.receives_sms}
            onChange={val => setField(val, 'receives_sms')}
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            id="line_one"
            label={t('Address line 1')}
            value={formData.line_one || ''}
            onChange={val => setField(val, 'line_one')}
          />
          <TextField
            id="line_two"
            label={t('Address line 2')}
            value={formData.line_two || ''}
            onChange={val => setField(val, 'line_two')}
          />
        </FieldBlock>
        <FieldBlock>
          <TextField
            id="city"
            label={t('City')}
            value={formData.city || ''}
            onChange={val => setField(val, 'city')}
          />
          <StateSelectField
            id="state"
            label={t('State')}
            state={formData.state}
            onSelect={val => setField(val, 'state')}
            clearable
          />
        </FieldBlock>
        <FieldBlock>
          <ZipCodeField
            id="zipcode"
            label={t('Zip code')}
            value={formData.zipcode || ''}
            onChange={val => setField(val, 'zipcode')}
          />
          <OrganizationSelectField
            id="organization"
            label={t('Organization')}
            onSelect={val => setField(val, 'organization')}
            organization={formData.organization}
            usePortal={false}
          />
        </FieldBlock>
        <FieldBlock>
          <SelectField
            id="primary_language"
            label={t('Primary language')}
            value={formData.primary_language || ''}
            options={languages.map(language => ({
              label: language,
              value: language,
            }))}
            onSelect={val => setField(val, 'primary_language')}
          />
        </FieldBlock>
        <ButtonBlock justify="right">
          <Button flat onClick={toggleForm}>
            {t('Cancel')}
          </Button>
          <Button
            raised
            primary
            type="submit"
            disabled={!isFormValid() || modalIsLoading}
          >
            {t('Add')}
          </Button>
        </ButtonBlock>
      </form>
      <CardError
        hide={!createPersonError}
        message={t('An error occurred while creating the person record')}
      />
    </div>
  )
}

export default AddGuestModalForm
