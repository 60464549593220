import { useState } from 'react'
import { Button, Icon } from '@politechdev/blocks-design-system'
import { StaticList } from 'components'
import { useTranslation } from 'react-i18next'
import FormListItem from './FormListItem'

const IneligibleForms = ({ forms, deliveryId, requiresLookupCodes }) => {
  const { t } = useTranslation()
  const [showForms, setShowForms] = useState(false)

  return (
    <>
      <div className="ineligible__message">
        {forms.length} other forms on this packet are ineligible for this
        delivery
        <Button
          onClick={() => {
            setShowForms(prevState => !prevState)
          }}
        >
          {showForms ? (
            <>
              <span>{t('Hide ineligible')}</span>{' '}
              <Icon.AngleUp aria-label={t('Hide ineligible')} />
            </>
          ) : (
            <>
              <span>{t('Show ineligible')}</span>{' '}
              <Icon.AngleDown aria-label={t('Show ineligible')} />
            </>
          )}
        </Button>
      </div>
      {showForms ? (
        <div className="ineligible">
          <StaticList
            itemData={forms}
            render={form => (
              <FormListItem
                key={form.id}
                form={form}
                deliveryId={deliveryId}
                requiresLookupCodes={requiresLookupCodes}
                isIneligible
              />
            )}
          />
        </div>
      ) : null}
    </>
  )
}

export default IneligibleForms
