import { useCurrent } from 'contexts/index'
import { defaultTo, merge } from 'lodash'
import { createContext, useContext, useState } from 'react'
import { qcPacketFields } from 'constants/qualityControl'
import { fetchPackets } from 'requests/qualityControl'
import {
  augmentPacket,
  inboxPacketFilters,
  isPacketCompleted,
} from 'utils/qualityControl'
import { isToday } from 'utils/dateTime'
import { fetchInspectedUser } from 'requests/tableQcManager'
import { DEFAULT_INSPECTED_USER } from 'constants/tableQCmanager'

export const QualityControlContext = createContext()

export const QualityControlProvider = ({ children }) => {
  const { currentTurfPerformsExternalQC, currentUser } = useCurrent()

  const [packets, setPackets] = useState([])
  const [inspectedUser, setInspectedUser] = useState(DEFAULT_INSPECTED_USER)

  const activePackets = packets.filter(
    shift =>
      !isPacketCompleted(shift) ||
      (isPacketCompleted(shift) && isToday(shift.completed_at))
  )

  const receivePackets = incomingPackets => {
    setPackets(incomingPackets.map(packet => augmentPacket(packet)))
  }

  const replacePacket = incomingPacket => {
    setPackets(oldPackets =>
      oldPackets.reduce((nextPackets, packet) => {
        if (packet.id === incomingPacket.id) {
          nextPackets.push(incomingPacket)
        } else {
          nextPackets.push(packet)
        }
        return nextPackets
      }, [])
    )
  }

  const getPackets = async params => {
    const rules = [
      inboxPacketFilters,
      { column: 'assignee_id', operator: 'is', param: currentUser.id },
      {
        column: 'unresolved_flag_triggers_stops_qc',
        operator: 'is',
        invert: 'true',
        param: true,
      },
    ]

    const { shifts } = await fetchPackets({
      current_page: 1,
      per: Number.MAX_SAFE_INTEGER,
      filters: { rules },
      ...params,
      fields: merge(
        [
          {
            forms: [
              'visual_review_approved',
              {
                visual_reviews: [
                  'id',
                  'created_at',
                  {
                    response: [
                      'id',
                      'response',
                      'description',
                      'implies_skips_phone_verification',
                    ],
                  },
                  {
                    user: ['id', 'name'],
                  },
                ],
              },
            ],
          },
        ],
        defaultTo(params.fields, {})
      ),
    })
    receivePackets(shifts)
    return { shifts }
  }
  const getInspectedUser = async id => {
    const { user } = await fetchInspectedUser(id, {
      fields: [
        'id',
        'name',
        'email',
        'turf_id',
        { turf: ['name', 'id'] },
        { role: ['name', 'admin', 'permissions'] },
        {
          assigned_shifts: qcPacketFields,
        },
      ],
    })

    const assigned_packets = user.assigned_shifts
      .filter(s => (currentTurfPerformsExternalQC ? s.qc_external : true))
      .filter(s => !s.unresolved_flags.some(f => f.trigger.stops_qc))
    setInspectedUser({ ...user, assigned_packets })
    receivePackets(assigned_packets)
  }

  const qualityControlContextProps = {
    activePackets,
    inspectedUser,
    setInspectedUser,
    receivePackets,
    getPackets,
    getInspectedUser,
    replacePacket,
  }

  return (
    <QualityControlContext.Provider value={qualityControlContextProps}>
      {children}
    </QualityControlContext.Provider>
  )
}

export const useQualityControl = () => useContext(QualityControlContext)
